<template>
  <div class="receivingManagement">
    <div class="header">
      <el-button type="info"
                 @click="downFile">导出</el-button>
      <div>
        <el-select v-model="queryState"
                   class="header-query"
                   placeholder="请选择状态">
          <el-option v-for="option in stateList"
                     :key="option.value"
                     :label="option.label"
                     :value="option.value"></el-option>
        </el-select>
        <el-date-picker v-model="queryTime"
                        type="daterange"
                        class="header-query"
                        range-separator="至"
                        value-format="yyyy-MM-dd"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
        </el-date-picker>
        <el-input placeholder="请选择标题"
                  class="header-query"
                  v-model="queryTitle"></el-input>
        <el-button type="primary"
                   @click="loadReceiving(1)">查询</el-button>
      </div>
    </div>
    <el-table :data="receivingList">
      <el-table-column label="文件标题"
                       align="center">
        <template slot-scope="scope">
          <el-tag :type="scope.row.is_urgent===1?'danger':'info'">{{scope.row.is_urgent===1?'紧急':'正常'}}</el-tag>
          {{scope.row.title}}
        </template>
      </el-table-column>
      <el-table-column label="收文日期"
                       prop="opertime"
                       align="center"></el-table-column>
      <el-table-column label="收文类型"
                       prop="typeStr"
                       align="center"></el-table-column>
      <el-table-column label="发件人"
                       prop="opername"
                       align="center"></el-table-column>
      <el-table-column label="归档状态"
                       align="center">
        <template slot-scope="scope">
          <el-tag :type="scope.row.file_statusStr!==1?'primary':'danger'">{{scope.row.file_statusStr}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="归档按钮"
                       align="center">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.file_status"
                     @change="fileStatusChange(scope.row.id)"
                     :disabled="scope.row.file_status"
                     active-text="是"
                     inactive-text="否">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column label="完成状态"
                       prop="statusStr"
                       align="center"></el-table-column>
      <el-table-column label="操作"
                       width="220px"
                       align="center">
        <template slot-scope="scope">
          <el-button type="warning"
                     size="mini"
                     v-if="scope.row.status===1"
                     @click="viewReceiving(scope.row,1)">提 交</el-button>
          <el-button type="info"
                     size="mini"
                     @click="modifyReceiving(scope.row,2)">编 辑</el-button>
          <el-button type="warning"
                     size="mini"
                     v-if="scope.row.status!==1"
                     @click="viewReceiving(scope.row,2)">查 看</el-button>
          <el-button type="primary"
                     size="mini"
                     @click="detailReceiving(scope.row.id)">详 情</el-button>

        </template>
      </el-table-column>
    </el-table>
    <el-pagination background
                   layout="prev,pager,next"
                   :page-count="page.pageCount"
                   :page-size="page.currentPage"
                   @current-change="loadReceiving"></el-pagination>
    <el-dialog :visible.sync="detailReceivingVisible"
               width="700px"
               title="查看公文">
      <el-form ref="receivingForm"
               class="receivingForm"
               :model="receivingForm"
               label-width="100px">
        <el-form-item label="文件标题">
          <el-input type="textarea"
                    disabled
                    autosize
                    v-model="receivingForm.title"></el-input>
        </el-form-item>
        <el-form-item label="来文单位">
          <el-input type="textarea"
                    disabled
                    autosize
                    v-model="receivingForm.unit"></el-input>
        </el-form-item>
        <el-form-item label="文件类型">
          <el-input type="text"
                    disabled
                    v-model="receivingForm.type"></el-input>
        </el-form-item>
        <el-form-item label="主要内容">
          <el-input type="textarea"
                    disabled
                    autosize
                    v-model="receivingForm.content"></el-input>
        </el-form-item>
        <el-form-item label="办公室意见">
          <el-input type="textarea"
                    autosize
                    disabled
                    v-model="receivingForm.proposal"></el-input>
        </el-form-item>
        <el-form-item label="校长批示">
          <el-input type="textarea"
                    autosize
                    disabled
                    v-model="receivingForm.bureauLeadersRemark"></el-input>
        </el-form-item>
        <el-form-item label="分管领导批示">
          <el-input type="textarea"
                    autosize
                    :disabled="viewDisable||viewOpinion2Disable"
                    v-model="receivingForm.leaderInChargeRemark"></el-input>
        </el-form-item>
        <el-form-item label="部门办理意见">
          <el-input type="textarea"
                    autosize
                    :disabled="viewDisable||viewOpinion3Disable"
                    v-model="receivingForm.departmentHandlingOpinions"></el-input>
        </el-form-item>
        <el-form-item label="添加文件">
          <el-upload class="upload-demo"
                     action="/api/base/uploadByOss.do"
                     :headers="{Authorization:token}"
                     :on-remove="onRemoveFile"
                     ref="upload"
                     :disabled="viewDisable"
                     :on-success="enclosureUploadSuccess"
                     :data="{path:'receivingManagement',name:''}"
                     :show-file-list="true">
            <el-button size="small"
                       :disabled="viewDisable"
                       type="primary">点击上传</el-button>
          </el-upload>
          <el-button type="primary"
                     size="small"
                     class="load-file"
                     @click="loadFile(receivingForm.file)"
                     v-if="receivingForm.file">下载附件</el-button>
        </el-form-item>
        <el-form-item label="是否归档">
          <template>
            <el-switch v-model="receivingForm.ifFile"
                       active-text="是"
                       :disabled="viewDisable"
                       inactive-text="否">
            </el-switch>
          </template>
        </el-form-item>
        <el-form-item label="接收人">
          <div>
            <div v-if="!viewDisable">
              <el-tag v-for="receiver in receiverList"
                      closable
                      @close="receiverClose(receiver.id)"
                      :key="receiver.id">{{receiver.label}}
              </el-tag>
            </div>
            <div v-if="viewDisable">
              <el-tag v-for="receiver in receiverList"
                      @close="receiverClose(receiver.id)"
                      :key="receiver.id">{{receiver.name}}
              </el-tag>
            </div>
            <el-button icon="el-icon-plus"
                       size="small"
                       v-if="!viewDisable"
                       @click="receiverClick"></el-button>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="detailReceivingVisible=false">取 消</el-button>
        <el-button type="primary"
                   @click="replyReceivingConfirm">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="receiverVisible"
               title="请选择接收人"
               width="600px">
      <el-tree :data="teacherList"
               expand-on-click-node
               class="tree-box"
               :default-expanded-keys="defaultExpandedKeys"
               ref="receiverTree"
               @check="receiverCheck"
               accordion
               node-key="key"
               show-checkbox></el-tree>
      <el-button @click="receiverVisible=false">取消</el-button>
      <el-button type="primary"
                 @click="receiverConfirm">确定
      </el-button>
    </el-dialog>
    <el-dialog :visible.sync="editReceivingFormVisible"
               title="编辑"
               width="600px">
      <el-form ref="editReceivingForm"
               :model="editReceivingForm"
               label-width="110px">
        <el-form-item label="编辑字段"
                      prop="title">
          <el-select v-model="editReceivingForm.type">
            <el-option label="分管领导批示"
                       value="分管领导批示"></el-option>
            <el-option label="部门办理意见"
                       value="部门办理意见"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="editReceivingForm.type"
                      prop="title">
          <el-input type="textarea"
                    autosize
                    v-model="editReceivingForm.content"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="editReceivingFormVisible=false">取 消</el-button>
        <el-button type="primary"
                   @click="editReceivingFormConfirm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "receivingManagement",
  data() {
    return {
      page: { currentPage: 0, pageCount: 1 },
      queryTime: "",
      queryTitle: "",
      receivingList: [],
      stateList: [
        { value: "", label: "全部" },
        { value: 1, label: "未归档" },
        { value: 2, label: "已归档" },
        { value: 3, label: "未完成" },
        { value: 4, label: "已完成" },
      ],
      queryState: "",
      detailReceivingVisible: false,
      receivingForm: {
        title: "",
        unit: "",
        content: "",
        id: "",
        proposal: "",
        bureauLeadersRemark: "",
        leaderInChargeRemark: "",
        departmentHandlingOpinions: "",
        type: "",
        file: "",
        fileUrl: "",
        fileName: "",
        ifFile: false,
        ifUrgent: false,
        time: "",
      },
      receiverList: [],
      teacherList: [],
      currentReceiverList: [],
      receiverVisible: false,
      defaultExpandedKeys: [], //打开接收人层数
      viewDisable: false,
      token: sessionStorage.getItem("token"),
      viewOpinion2Disable: false,
      viewOpinion3Disable: false,
      editReceivingFormVisible: false,
      editReceivingForm: {
        type: "分管领导批示",
        content: "",
        did: 0,
      },
      editType: 0,
    };
  },
  created() {
    this.loadReceiving(1);
    this.loadTeacherList();
  },
  methods: {
    loadReceiving(page) {
      const that = this;
      let data = { indexNo: page, status: this.queryState };
      if (this.queryTitle) {
        data.title = this.queryTitle;
      }
      if (this.queryTime) {
        data.start_time = this.queryTime[0];
        data.end_time = this.queryTime[1];
      }
      if (this.queryState) {
        data.status = this.queryState;
      }
      this.$post("/base/queryDocumentReceive.do", data)
        .then((res) => {
          let newReceivingList = res.data.rows;
          newReceivingList.forEach((receiving) => {
            receiving.file_status = receiving.file_status === 2;
          });
          that.receivingList = newReceivingList;
          that.page.currentPage = res.data.indexNo;
          that.page.pageCount = res.data.pageCount;
        })
        .catch((err) => {
          that.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    loadTeacherList() {
      const that = this;
      this.$post("/media/queryBookByTea.do", {})
        .then((res) => {
          res.data[0].children.forEach((department) => {
            this.defaultExpandedKeys.push(department.key);
          });
          this.teacherList = res.data;
        })
        .catch((err) => {
          that.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    viewReceiving(row, type) {
      //2查看，1提交
      if (type === 2) {
        this.viewDisable = true;
      } else {
        this.viewDisable = false;
        if (row.opinion2) {
          this.viewOpinion2Disable = true;
        }
        if (row.opinion3) {
          this.viewOpinion3Disable = true;
        }
      }
      if (this.receivingForm.file) {
        this.$refs.upload.clearFiles();
      }
      this.loadHistory(row.id);
      this.detailReceivingVisible = true;
      this.receivingForm.title = row.title;
      this.receivingForm.unit = row.unit;
      this.receivingForm.type = row.typeStr;
      this.receivingForm.content = row.content;
      this.receivingForm.id = row.id;
      this.receivingForm.ifFile = row.file_status;
      this.receiverList = row.list;
      this.receivingForm.file = row.fileUrl;
    },
    replyReceivingConfirm() {
      if (this.receivingForm.ifFile && this.receiverList.length !== 0) {
        this.$message({
          type: "warning",
          message: "归档后不能选择接收人",
        });
        return;
      }
      const that = this;
      let receiverId = [];
      this.receiverList.forEach((receiver) => {
        receiverId.push(receiver.id);
      });
      let receiverIds = receiverId.join(",");
      let data = {
        did: this.receivingForm.id,
        opinion1: this.receivingForm.bureauLeadersRemark,
        fileName: this.receivingForm.fileName,
        fileUrl: this.receivingForm.fileUrl,
        teachers: receiverIds,
        file_status: this.receivingForm.ifFile ? 2 : 1,
        opinion2: this.receivingForm.leaderInChargeRemark,
        opinion3: this.receivingForm.departmentHandlingOpinions,
      };
      this.$confirm("是否确认提交该申请？", "tips", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("/base/readDocument.do", data)
            .then((res) => {
              that.$message({ type: "success", message: res.message });
              that.loadReceiving(1);
              that.detailReceivingVisible = false;
            })
            .catch((err) => {
              that.$message({ type: "warning", message: err.message });
              that.detailReceivingVisible = false;
            });
        })
        .catch(() => {
          this.$message({ type: "warning", message: "已取消删除" });
        });
    },
    receiverClick() {
      this.receiverVisible = true;
      this.$nextTick(() => {
        this.$refs.receiverTree.setCheckedKeys([]);
        this.$refs.receiverTree.setCheckedNodes(this.currentReceiverList);
      });
    },
    receiverCheck(row, rowArray) {
      this.currentReceiverList = [];
      rowArray.checkedNodes.forEach((checked) => {
        if (checked.type === 1) {
          this.currentReceiverList.push(checked);
        }
      });
    },
    receiverConfirm() {
      this.receiverList = this.filterRepeat(this.currentReceiverList);
      this.currentReceiverList = this.receiverList;
      this.receiverVisible = false;
    },
    //数组对象去重
    filterRepeat(arr) {
      arr = arr.filter((element, index, self) => {
        return self.findIndex((el) => el.id === element.id) === index;
      });
      return arr;
    },
    receiverClose(receiverId) {
      this.receiverList.forEach((receiver, index) => {
        if (receiver.id === receiverId) {
          this.receiverList.splice(index, 1);
        }
      });
      this.currentReceiverList = this.receiverList;
    },
    detailReceiving(id) {
      this.$router.push({ name: "viewFile", query: { id } });
    },
    fileStatusChange(id) {
      this.$post("/base/fileDocument.do", { did: id })
        .then((res) => {
          this.loadReceiving(1);
          this.$message({ type: "success", message: res.message });
        })
        .catch((err) => {
          this.$message({ type: "warning", message: err.message });
        });
    },
    enclosureUploadSuccess(res, file) {
      if (this.receivingForm.fileName) {
        this.receivingForm.fileName = `${this.receivingForm.fileName},${file.name}`;
      } else {
        this.receivingForm.fileName = file.name;
      }
      if (this.receivingForm.fileUrl) {
        this.receivingForm.fileUrl = `${this.receivingForm.fileUrl},${file.response.message}`;
      } else {
        this.receivingForm.fileUrl = file.response.message;
      }
    },
    loadFile(url) {
      let urlList = url.split(",");
      if (urlList.length === 1) {
        window.open(this.$fileUploadDomain + url);
      } else {
        this.downZip(urlList);
      }
    },
    // 批量下载
    downZip(urlList) {
      const that = this;
      this.$postFile("/base/ZipOss.do", urlList)
        .then((res) => {
          const blob = new Blob([res.data], {
            type: "application/zip",
          });
          const a = document.createElement("a");
          // 生成文件路径
          let href = window.URL.createObjectURL(blob);
          a.href = href;
          a.download = "导出";
          // 利用a标签做下载
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(href);
        })
        .catch((error) => {
          that.$message({
            type: "warning",
            message: error.message,
          });
        });
    },
    // 文件移除的钩子
    onRemoveFile(res, file) {
      this.receivingForm.fileUrl = "";
      this.receivingForm.fileName = "";
      if (file.length !== 0) {
        file.forEach((newFile) => {
          if (this.receivingForm.fileName) {
            this.receivingForm.fileName = `${this.receivingForm.fileName},${newFile.name}`;
          } else {
            this.receivingForm.fileName = newFile.name;
          }
          if (this.receivingForm.fileUrl) {
            this.receivingForm.fileUrl = `${this.receivingForm.fileUrl},${newFile.response.message}`;
          } else {
            this.receivingForm.fileUrl = newFile.response.message;
          }
        });
      }
    },
    downFile() {
      const that = this;
      let paramas = new FormData();
      paramas.append("status", this.queryState);
      paramas.append("title", this.queryTitle);
      if (this.queryTime[0]) {
        paramas.append("start_time", this.queryTime[0]);
        paramas.append("end_time", this.queryTime[1]);
      }
      this.$postFile("/base/exportDocumentReceive.do", paramas)
        .then((res) => {
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel;",
          });
          const a = document.createElement("a");
          // 生成文件路径
          let href = window.URL.createObjectURL(blob);
          a.href = href;
          a.download = "导出";
          // 利用a标签做下载
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(href);
        })
        .catch((error) => {
          that.$message({
            type: "warning",
            message: error.message,
          });
        });
    },
    modifyReceiving(row, type) {
      this.editReceivingFormVisible = true;
      this.editReceivingForm.did = row.id;
      this.editType = type;
      if (type === 3) {
        this.editReceivingForm.type = "部门办理意见";
      }
    },
    editReceivingFormConfirm() {
      let data = {
        did: this.editReceivingForm.did,
        type: this.editType, //0办公室意见1校长意见2分管领导批示3部门办理意见
        opinion: this.editReceivingForm.content,
      };
      if (this.editReceivingForm.type === "部门办理意见") {
        data.type = 3;
      }
      this.$confirm("是否确认修改？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("/base/UpdateOpinionByDid.do", data)
            .then((res) => {
              this.$message({ type: "success", message: res.message });
              this.loadReceiving(1);
              this.editReceivingFormVisible = false;
            })
            .catch((err) => {
              this.editReceivingFormVisible = false;
              this.$message({ type: "warning", message: err.message });
            });
        })
        .catch(() => {
          this.$message({ type: "warning", message: "已取消确认" });
        });
    },
    loadHistory(id) {
      this.$post("/base/queryOpinionListByDid.do", { did: id })
        .then((res) => {
          this.receivingForm.proposal = "";
          this.receivingForm.bureauLeadersRemark = "";
          this.receivingForm.leaderInChargeRemark = "";
          this.receivingForm.departmentHandlingOpinions = "";
          res.data.forEach((row) => {
            if (row.type === 0) {
              if (row.list) {
                this.receivingForm.proposal = row.list.join("\n");
              }
            } else if (row.type === 1) {
              if (row.list) {
                this.receivingForm.bureauLeadersRemark = row.list.join("\n");
              }
            } else if (row.type === 2) {
              if (row.list) {
                this.receivingForm.leaderInChargeRemark = row.list.join("\n");
              }
            } else if (row.type === 3) {
              if (row.list) {
                this.receivingForm.departmentHandlingOpinions =
                  row.list.join("\n");
              }
            }
          });
        })
        .catch((err) => {
          that.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  display: flex;
  justify-content: space-between;
}
.receivingForm {
  height: 500px;
  overflow-x: auto;
}
.header-query {
  margin: 0 5px;
}
.receivingForm-flex {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.tree-box {
  height: 500px;
  overflow-y: auto;
}
</style>
